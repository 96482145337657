export const FETCH_PRODUCT_ADDRESS_START = "FETCH_PRODUCT_ADDRESS_START";
export const FETCH_PRODUCT_ADDRESS_SUCCESS = "FETCH_PRODUCT_ADDRESS_SUCCESS";
export const FETCH_PRODUCT_ADDRESS_ERROR = "FETCH_PRODUCT_ADDRESS_ERROR";

export const ADD_PRODUCT_ADDRESS_START = "ADD_PRODUCT_ADDRESS_START";
export const ADD_PRODUCT_ADDRESS_SUCCESS = "ADD_PRODUCT_ADDRESS_SUCCESS";
export const ADD_PRODUCT_ADDRESS_ERROR = "ADD_PRODUCT_ADDRESS_ERROR";

export const EDIT_PRODUCT_ADDRESS_START = "EDIT_PRODUCT_ADDRESS_START";
export const EDIT_PRODUCT_ADDRESS_SUCCESS = "EDIT_PRODUCT_ADDRESS_SUCCESS";
export const EDIT_PRODUCT_ADDRESS_ERROR = "EDIT_PRODUCT_ADDRESS_ERROR";

export const DELETE_PRODUCT_ADDRESS_START = "DELETE_PRODUCT_ADDRESS_START";
export const DELETE_PRODUCT_ADDRESS_SUCCESS = "DELETE_PRODUCT_ADDRESS_SUCCESS";
export const DELETE_PRODUCT_ADDRESS_ERROR = "DELETE_PRODUCT_ADDRESS_ERROR";
