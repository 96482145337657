import {
  FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED,
  FETCH_CLIENT_MM_WITHDRAWALS_SUCCESS,
  FETCH_CLIENT_MM_WITHDRAWALS_FAIL,

  UNSUBSCRIBE_STRATEGY,
  // UNSUBSCRIBE_STRATEGY_SUCCESS,
  UNSUBSCRIBE_STRATEGY_FAIL,
} from "./actionTypes";

const initalState = {
  withdrawals: [],
  loading: false,
  error: "",
  withdrawResponseMessage: "",
  modalClear: false
};
const withdrawalReducer = (state = { initalState }, action) => {
  switch (action.type) {
    // fetch client withdrawals 
    case FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED:
    case UNSUBSCRIBE_STRATEGY:
      state = {
        ...state,
        loading: true
      };
      break;
    case FETCH_CLIENT_MM_WITHDRAWALS_FAIL:
    case UNSUBSCRIBE_STRATEGY_FAIL:
      state = {
        ...state,
        loading: false,
        error: false
      };
      break;
    case FETCH_CLIENT_MM_WITHDRAWALS_SUCCESS:
      state = {
        ...state,
        withdrawals: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        loading: false,
        error: false
      };
      break;
    // case UNSUBSCRIBE_STRATEGY_SUCCESS:
    //   state = {
    //     ...state,
    //     clientSubscription: [...state.clientSubscription, action.payload],
    //     loading: false,
    //     error: false
    //   };
    //   break;
    default:
      state = { ...state };
  }
  return state;
};
export default withdrawalReducer;