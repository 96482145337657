import CardWrapper from "components/Common/CardWrapper";
import { JClickHandler } from "components/Journey/handlers";
import ProfileBasicStats from "pages/Forex/Dashboard/InvestorPortal/MasterTraders/ProfileBasicStats";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardBody, CardFooter, CardTitle, Col
} from "reactstrap";
import { toggleCurrentModal } from "store/actions";

export default function CardView({ 
  masterTrader, setProfile,
}) {
  const { clientData } = useSelector((state) => state.Profile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getProfileName = (profileType) => {
    switch (profileType) {
      case 1 : return " MAM";
      case 2 : return " PAM";
      case 9: return " Copy Trading/Social Trading";
      default : return "";
    }
  };
  return (
    <Col md={3} className="my-2">
      <CardWrapper className="accounts-tab shadow glass-card">
        <CardTitle className="text-center">
          {/* name and avatar */}
          <ProfileBasicStats profile={masterTrader} />
        </CardTitle>
        <CardBody className="my-3 text-center">
          <div className="d-flex justify-content-between">
            <h5>{t("Username")}: </h5>
            <h5 className="color-primary">{masterTrader.username ?? 0}</h5>
          </div>
          <div className="d-flex justify-content-between">
            <h5>{t("Equity")}: </h5>
            <h5 className="color-primary">{masterTrader.equity ?? 0}</h5>
          </div>
          <div className="d-flex justify-content-between my-1">
            <h5>{t("Profile Type")}: </h5>
            <h5 className="color-primary">{getProfileName(masterTrader.profileType)}</h5>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h5>{t("Investors")}: </h5>
            <h5 className="color-primary">{masterTrader.investors ?? 0}</h5>
          </div>
          {/* <div className="d-flex justify-content-between my-2">
            <h5>{t("Master Login")}: </h5>
            <h5 className="color-primary">{masterTrader.login ?? "-"}</h5>
          </div> */}
          <div className="d-flex justify-content-between">
            <h5>{t("Total Profit Made")}: </h5>
            <h5 className="color-primary">{masterTrader.masterTradingAccountId?.totalProfit ?? 0}</h5>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-primary color-bg-btn border-0" color="primary w-100" onClick={()=>{
            setProfile(masterTrader);
            JClickHandler("Invest", {
              ...clientData.stages,
              loaded: true,
            }, dispatch, toggleCurrentModal, "INVESTOR")();
          }}>
            {t("Invest")}
          </Button>
        </CardFooter>
      </CardWrapper>
    </Col>
  );
}
