import {
  FETCH_GOLD_START,
  FETCH_GOLD_SUCCESS,
  FETCH_GOLD_ERROR,
} from "./actionTypes";

export const fetchGold = (params = {}) => {
  return {
    type: FETCH_GOLD_START,
    payload: params,
  };
};
export const fetchGoldSuccess = (data) => {
  return {
    type: FETCH_GOLD_SUCCESS,
    payload: data,
  };
};
export const fetchGoldError = (error) => {
  return {
    type: FETCH_GOLD_ERROR,
    payload: { error },
  };
};