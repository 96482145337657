import { all, fork } from "redux-saga/effects";

import depositsSaga from "./transactions/deposit/saga";
import withdrawalSaga from "./transactions/withdrawal/saga";
import strategySaga from "./strategy/saga";

export default function* forexSaga() {
  yield all([
    fork(depositsSaga),
    fork(withdrawalSaga),
    fork(strategySaga),
  ]);
}
