import logo from "assets/images/logos/100x40.png";
import sm from "assets/images/logos/30x40.png";
import auth from "assets/images/logos/320x120.png";


export const clientName = "Guldzon";
export const developedBy = "Guldzon";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "Guldzon";
export const companyWebsite = "";
export const privacyPolicyLink = "https://my.guldzon.com/assets/Privacy-Policy.pdf";
export const clientAgreementLink = "https://my.guldzon.com/assets/complaints%20Guld%20Zon%20Arabic%20-%20Tracked.pdf";
export const sidebarLogo = logo;
export const smLogo = sm;
export const mainLogo = logo;
export const authLogo = auth;
