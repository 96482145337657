import React, { useEffect } from "react";
import {
  connect, useDispatch, useSelector
} from "react-redux";
import { Container } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import StrategyCard from "./NewStrategyCard";
//i18n
import { withTranslation } from "react-i18next";
import {
  fetchStrategies, fetchClientSubscription, fetchWallets
} from "store/actions";


const Strategies = (props) => {

  const dispatch = useDispatch();

  const { clientSubscription } = useSelector((state) => state.mm.deposit);
  const categories = [ {
    name: "OUNCE",
    recordId: 2,
    details: {
      price: "$1,788.95"
    }
  }, {
    name: "KG",
    recordId: 3,
    details: {
      price: "$57,429"
    }
  }, {
    name: "BAR",
    recordId: 1,
    details: {
      price: "$712,119.6"
    }
  }];

  useEffect(() => {
    dispatch(fetchStrategies());
    dispatch(fetchClientSubscription());
    dispatch(fetchWallets());
  }, []);


  return (
    <Container className="mb-4">
      <h1 className='mb-2'>{props.t("How it works")}</h1>
      <p className='text-muted mb-4'>{props.t("Subscribe to any of our Plans")}</p>
      <div className="strategy-container">
        {categories?.map((st) =>
          <StrategyCard key={st.recordId} strategy={st} clientSubscription={clientSubscription} />
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  deposits: state.crypto.depositsReducer.deposits,
  totalDeposits: state.crypto.depositsReducer.totalDocs,
  loading: state.crypto.depositsReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(Strategies)); 