import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  FETCH_GOLD_START,
} from "./actionTypes";
import {
  fetchGoldSuccess,
  fetchGoldError,
} from "./actions";

//Include Both Helper File with needed methods
import * as goldApi from "../../../apis/gold/gold";

function* fetchGold(params = {}) {
  try {
    const data = yield call(goldApi.getGold, params);
    yield put(fetchGoldSuccess(data));
  } catch (error) {
    yield put(fetchGoldError(error));
  }
}


function* productsSaga() {
  yield takeEvery(FETCH_GOLD_START, fetchGold);
}

export default productsSaga;
