import {
  FETCH_GOLD_WITHDRAWALS_ERROR,
  FETCH_GOLD_WITHDRAWALS_START,
  FETCH_GOLD_WITHDRAWALS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  withdrawals: [],
  withdrawalsPagination: {},
  transaction: {
    loading: false,
    success: false,
    clear: false,
  },
};
const depositsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOLD_WITHDRAWALS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_GOLD_WITHDRAWALS_SUCCESS:
      state = {
        ...state,
        loading: false,
        withdrawals: action.payload.docs,
        withdrawalsPagination: {
          ...action.payload,
          docs: undefined
        },
      };
      break;
    case FETCH_GOLD_WITHDRAWALS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default depositsReducer;
