import {
  FETCH_CLIENT_DEPOSITS_REQUESTED,
  FETCH_CLIENT_DEPOSITS_SUCCESS,
  FETCH_CLIENT_DEPOSITS_FAIL,

  FETCH_CLIENT_SUBSCRIPTION_REQUESTED,
  FETCH_CLIENT_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUBSCRIPTION_FAIL,

  SUBSCRIBE_TO_STRATEGY,
  SUBSCRIBE_TO_STRATEGY_SUCCESS,
  SUBSCRIBE_TO_STRATEGY_FAIL,

  FETCH_CLIENT_PROFIT,
  FETCH_CLIENT_PROFIT_SUCCESS,
  FETCH_CLIENT_PROFIT_FAIL,
} from "./actionTypes";

// fetch client deposits
export const fetchClientDeposits = (params = {}) => {
  return {
    type: FETCH_CLIENT_DEPOSITS_REQUESTED,
    payload: params
  };
};
export const fetchClientDepositsSuccess = (data) => {
  return {
    type: FETCH_CLIENT_DEPOSITS_SUCCESS,
    payload: data
  };
};
export const fetchClientDepositsFail = (error) => {
  return {
    type: FETCH_CLIENT_DEPOSITS_FAIL,
    payload: { error }
  };
};

// fetch client mm deposits
export const fetchClientSubscription = (params = {}) => {
  return {
    type: FETCH_CLIENT_SUBSCRIPTION_REQUESTED,
    payload: params
  };
};
export const fetchClientSubscriptionSuccess = (data) => {
  return {
    type: FETCH_CLIENT_SUBSCRIPTION_SUCCESS,
    payload: data
  };
};
export const fetchClientSubscriptionFail = (error) => {
  return {
    type: FETCH_CLIENT_SUBSCRIPTION_FAIL,
    payload: { error }
  };
};

export const SubToStrategy = (payload) => {
  return {
    type: SUBSCRIBE_TO_STRATEGY,
    payload,
  };
};
export const SubToStrategySuccess = (params = {}) => {
  return {
    type: SUBSCRIBE_TO_STRATEGY_SUCCESS,
    payload: params,
  };
};
export const SubToStrategyFail = (error) => {
  return {
    type: SUBSCRIBE_TO_STRATEGY_FAIL,
    payload: { error }
  };
};
export const fetchClientProfit = (params = {}) => {
  return {
    type: FETCH_CLIENT_PROFIT,
    payload: params
  };
};
export const fetchClientProfitSuccess = (data) => {
  return {
    type: FETCH_CLIENT_PROFIT_SUCCESS,
    payload: data
  };
};
export const fetchClientProfitFail = (error) => {
  return {
    type: FETCH_CLIENT_PROFIT_FAIL,
    payload: { error }
  };
};
