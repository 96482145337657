import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import CardWrapper from "components/Common/CardWrapper";
import Widgets from "components/Common/Widgets";
import Transactions from "./Transactions";
import Deposits from "./Deposits";
import Withdrawals from "./Withdrawals";
import AllFilters from "./filters/AllFilters";
import { withTranslation } from "react-i18next";
import WithdrawalFilters from "./filters/WithdrawalFilters";
import DepositFilters from "./filters/DepositFilters";
import { fetchWallets } from "store/actions";

function WalletTransactions(props) {
  const dispatch = useDispatch();
  const [activeTab, setTab] = useState(0);
  const [transactionsFilterObj, setTransactionsFilterObj] = useState({});
  const [depositsFilterObj, setDepositsFilterObj] = useState({});
  const [withdrawalsFilterObj, setWithdrawalsFilterObj] = useState({});
  // const [convertsFilterObj, setConvertsFilterObj] = useState({});
  const { assets } = useSelector(state=>state.assets);
  const { subPortal } = useSelector(state=>state.Layout);
  const { wallets } = useSelector(state=>state.walletReducer);
  const isDemo = subPortal === "DEMO";
  useEffect(() => {
    if (!wallets || wallets.length === 0)
      dispatch(fetchWallets({
        isDemo,
      }));
  }, []);  
  const transactionsFilterHandler = (filter) => {
    filter.fromDate === "" && delete filter.fromDate;
    filter.toDate === "" && delete filter.toDate;
    setTransactionsFilterObj(filter);
  };

  const depositsFilterHandler = (filter) => {
    filter.fromDate === "" && delete filter.fromDate;
    filter.toDate === "" && delete filter.toDate;
    setDepositsFilterObj(filter);
  };

  const withdrawalsFilterHandler = (filter) => {
    filter.fromDate === "" && delete filter.fromDate;
    filter.toDate === "" && delete filter.toDate;
    setWithdrawalsFilterObj(filter);
  };

  const mainWallets = wallets.filter(w => !w.isInventory && !w.isCrypto);

  return (
    <>
      <Container className="mb-5 mt-5">
        <div className="d-flex flex-row">
          <h1 className="mb-4">
            {props.t("Wallet Transactions")}
          </h1>
        </div>
        <Widgets
          setTab={setTab}
          tabs={["All", "Withdrawls", "Deposit"]}
        >
          {
            activeTab === 0 && <AllFilters filterHandler={transactionsFilterHandler} wallets={mainWallets} />
          }
          {
            activeTab === 2 && <DepositFilters filterHandler={depositsFilterHandler} wallets={mainWallets} />
          }
          {
            activeTab === 1 && <WithdrawalFilters filterHandler={withdrawalsFilterHandler} wallets={mainWallets} />
          }
          {/* {
            activeTab === 4 && <ConvertFilters filterHandler={convertsFilterHandler} wallets={mainWallets} />
          } */}
          <CardWrapper>
            <Container>
              {activeTab === 0 && <Transactions assets={assets} filterObj={transactionsFilterObj} isDemo={isDemo} />}
              {activeTab === 2 && <Deposits assets={assets} filterObj={depositsFilterObj} isDemo={isDemo} />}
              {activeTab === 1 && <Withdrawals assets={assets} filterObj={withdrawalsFilterObj} isDemo={isDemo} />}
              {/* {activeTab === 4 && <Convert assets={assets} filterObj={convertsFilterObj} />} */}
            </Container>
          </CardWrapper>
        </Widgets>
      </Container>
    </>
  );
}

export default withTranslation()(WalletTransactions); 