import React, { lazy } from "react";
const CryptoModals = lazy(() => import("./Crypto"));
const ForexModals = lazy(() => import("./Forex/Forex"));
const MmModals = lazy(() => import("./MoneyManagement"));
const GoldModals = lazy(() => import("./Gold"));
const JourneyModals = lazy(() => import("./JourneyModals"));
const WalletModals = lazy(() => import("./Wallets/Wallet"));
const DepositsModal = lazy(() => import("./Deposits/DepositModals"));
const WithdrawModals = lazy(() => import("./Withdrawals/WithdrawModals"));

const Modals = () => {
  return (
    <>
      <CryptoModals />
      <ForexModals />
      <MmModals />
      <GoldModals />
      <JourneyModals />
      <WalletModals />
      <DepositsModal />
      <WithdrawModals />
    </>
  );
};

export default Modals;