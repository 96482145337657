import { all, fork } from "redux-saga/effects";

import generalSaga from "./general/sagas";
import cryptoSaga from "./crypto/sagas";
import forexSaga from "./forex/sagas";
import mmSaga from "./mm/sagas";
import goldSaga from "./gold/sagas";
import walletSaga from "./wallets/saga";

export default function* rootSaga() {
  yield all([
    fork(generalSaga),
    fork(cryptoSaga),
    fork(forexSaga),
    fork(mmSaga),
    fork(goldSaga),
    fork(walletSaga)
  ]);
}
