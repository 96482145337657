import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import {
  deleteProductAddressError,
  deleteProductAddressSuccess,
  editProductAddressError,
  editProductAddressSuccess,
  fetchAddressesError,
  fetchAddressesSuccess
} from "./actions";
import {
  addCustomerAddress,
  editCustomerAddress,
  getCustomerAddress
} from "apis/gold";
import {
  ADD_PRODUCT_ADDRESS_START,
  DELETE_PRODUCT_ADDRESS_START,
  EDIT_PRODUCT_ADDRESS_START,
  FETCH_PRODUCT_ADDRESS_START
} from "./actionTypes";
import { showErrorNotification, showSuccessNotification } from "store/general/notifications/actions";

function* fetchAddresses({ payload }) {
  try {
    const data = yield call(getCustomerAddress, payload);
    yield put(fetchAddressesSuccess(data));
  } catch (error) {
    yield put(fetchAddressesError(error));
  }
}

function* addProductAddress({ payload }) {
  try {
    const data = yield call(addCustomerAddress, payload);
    yield put(editProductAddressSuccess(data));
    yield put(showSuccessNotification("Address added successfully"));
  } catch (error) {
    yield put(editProductAddressError(error));
    yield put(showErrorNotification("Failed to add address"));
  }
}

function* editProductAddress({ payload }) {
  try {
    const data = yield call(editCustomerAddress, payload);
    yield put(editProductAddressSuccess(data));
    yield put(showSuccessNotification("Address updated successfully"));
  } catch (error) {
    yield put(editProductAddressError(error));
    yield put(showErrorNotification("Failed to update address"));
  }
}

function* deleteProductAddress(payload) {
  try {
    const data = yield call(deleteProductAddress, payload);
    yield put(deleteProductAddressSuccess(data));
    yield put(showSuccessNotification("Address deleted successfully"));
  } catch (error) {
    yield put(deleteProductAddressError(error));
    yield put(showErrorNotification("Failed to delete address"));
  }
}

function* addressSaga() {
  yield takeEvery(FETCH_PRODUCT_ADDRESS_START, fetchAddresses);
  yield takeEvery(ADD_PRODUCT_ADDRESS_START, addProductAddress);
  yield takeEvery(EDIT_PRODUCT_ADDRESS_START, editProductAddress);
  yield takeEvery(DELETE_PRODUCT_ADDRESS_START, deleteProductAddress);
}

export default addressSaga;