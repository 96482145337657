import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import { fetchGoldDepositsError, fetchGoldDepositsSuccess } from "./actions";
import { getGoldDeposits } from "apis/gold";
import { FETCH_GOLD_DEPOSITS_START } from "./actionTypes";

function* fetchGoldDeposits({ payload }) {
  try {
    const data = yield call(getGoldDeposits, payload);
    yield put(fetchGoldDepositsSuccess(data));
  } catch (error) {
    yield put(fetchGoldDepositsError(error));
  }
}

function* depositsSaga() {
  yield takeEvery(FETCH_GOLD_DEPOSITS_START, fetchGoldDeposits);
}

export default depositsSaga;