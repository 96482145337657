/* eslint-disable indent */
import { searchAccountAPI } from "apis/forex/accounts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import CustomSelect from "components/Common/CustomSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import {
  Alert,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { postLinkReqInit } from "store/actions";

const { SingleValue, Option } = components;

const AccountSelect = (props) => {
  const { t } = useTranslation();

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div
        className="color-primary font-weight-bold border-0"
        style={{ fontSize: "12px" }}
      ></div>
      <div style={{ fontWeight: "bold" }}>{props.data.label}</div>
    </SingleValue>
  );

  const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;

  const customStyles = {
    // Custom styles for the select component
    // ...
  };

  return (
    <>
      <div style={{ backgroundColor: "transparent" }}>
        <CustomSelect
          {...props}
          className="basic-single color-primary shadow border-0"
          placeholder={t("Select Profile")}
          styles={customStyles}
          onInputChange={(input) => {
            if (input !== "") {
              if (!/^-?\d*\.?\d*$/.test(input)) {
                return "";
              }
            }
            return input;
          }}
          components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
          }}
          options={props.options}
        />
      </div>
    </>
  );
};

const LinkInvestorModal = ({ isOpen, toggle, profiles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [accountNo, setAccountNo] = useState();
  const [error, setError] = useState(null);
  // const [typeOptions, setTypeOptions] = useState([
  //   {
  //     label: "New",
  //     value: "New",
  //   },
  //   {
  //     label: "Existing",
  //     value: "Existing",
  //   },
  // ]);

  const handleProfileChange = (selectedOption) => {
    setSelectedProfile(selectedOption);
    setSelectedInvestor(null);
  };

  const handleSubmit = () => {
    console.log(selectedProfile, selectedInvestor);
    const payload = {
      params : {
        profileId: selectedProfile.value,
        investorId: selectedInvestor._id,
      }
    };
    dispatch(
      postLinkReqInit(payload)
    );
  };

  useEffect(() => {
    if (accountNo && selectedProfile) {
      searchAccountAPI({
        value: accountNo,
        type: "INVESTOR",
      }).then((res) => {
        if (res?.isSuccess) {
          setSelectedInvestor(res?.result);
          if (!profiles?.length) {
            throw new Error("No profiles found");
          }
          const profile = profiles?.find(
            (profile) => profile._id === selectedProfile.value
          );
          const slaves = profile?.slaves;
          const isAlreadyAdded = slaves?.find(
            (slave) => slave.tradingAccountId === res?.result?._id
          );
          if (isAlreadyAdded) {
            throw new Error("Investor already linked to this profile");
          } else {
            setError(null);
          }
        } else {
          throw new Error(res?.message);
        }
      }).catch((err) => {
        console.log(err);
        setError(err?.message);
      });
    } else {
      setError(null);
    }
  }, [accountNo, selectedProfile]);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Link Investor To SP")}
      </ModalHeader>
      <ModalBody>
        <AvForm>
          <Label className="form-label mb-2">{t("Profile")}</Label>
          <AccountSelect
            options={
              profiles && profiles.length
                ? profiles.map((profile) => ({
                    label: profile.masterTradingAccountId.login,
                    value: profile._id,
                  }))
                : []
            }
            value={selectedProfile}
            onChange={handleProfileChange}
          />
          <Label className="form-label mt-3">
            {t("Investor Account number")}
          </Label>
          <AvField
            name="investorAccount"
            className="form-control"
            errorMessage={"Invalid Account number"}
            onChange={(e) => {
              setAccountNo(e.target.value);
            }}
            min="0"
            onKeyPress={(e) => {
              if (!/^\d{0,8}(\.\d{0,2})?$/.test(e.target.value + e.key))
                e.preventDefault();
            }}
            required
          />
          <Row className="my-3 mx-3">
            <Alert color="danger" isOpen={error !== null}>
              {error}
            </Alert>
          </Row>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-2 mx-auto">
          <Button onClick={toggle}>{t("Cancel")}</Button>
          <Button
            disabled={
              !selectedProfile ||
              !selectedInvestor ||
              !accountNo ||
              error !== null
            }
            onClick={handleSubmit}
            className="btn btn-success"
            type="submit"
          >
            {t("Link")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default LinkInvestorModal;
