import {
  BUY_GOLD_START,
  BUY_GOLD_SUCCESS,
  BUY_GOLD_ERROR,
  BUY_GOLD_CLEAR,
  FETCH_GOLD_ORDERS_START,
  FETCH_GOLD_ORDERS_SUCCESS,
  FETCH_GOLD_ORDERS_ERROR,
  REQUEST_STORAGE_TO_DELIVER_START,
  REQUEST_STORAGE_TO_DELIVER_SUCCESS,
  REQUEST_STORAGE_TO_DELIVER_ERROR,
  RESELL_GOLD_START,
  RESELL_GOLD_SUCCESS,
  RESELL_GOLD_ERROR,
  CLEAR_GOLD_INVENTORY_TRANSACTIONS,
} from "./actionTypes";


export const buyGold = (payload) => {
  return {
    type: BUY_GOLD_START,
    payload: payload,
  };
};

export const buyGoldSuccess = (data) => {
  return {
    type: BUY_GOLD_SUCCESS,
    payload: data,
  };
};

export const buyGoldError = (error) => {
  return {
    type: BUY_GOLD_ERROR,
    payload: { error },
  };
};

export const buyGoldClear = () => {
  return {
    type: BUY_GOLD_CLEAR,
  };
};

export const fetchGoldOrders = (params = {}) => {
  return {
    type: FETCH_GOLD_ORDERS_START,
    payload: params,
  };
};

export const fetchGoldOrdersSuccess = (data) => {
  return {
    type: FETCH_GOLD_ORDERS_SUCCESS,
    payload: data,
  };
};

export const fetchGoldOrdersError = (error) => {
  return {
    type: FETCH_GOLD_ORDERS_ERROR,
    payload: { error },
  };
};

export const requestStorageToDeliver = (payload) => {
  return {
    type: REQUEST_STORAGE_TO_DELIVER_START,
    payload: payload,
  };
};

export const requestStorageToDeliverSuccess = (data) => {
  return {
    type: REQUEST_STORAGE_TO_DELIVER_SUCCESS,
    payload: data,
  };
};

export const requestStorageToDeliverError = (error) => {
  return {
    type: REQUEST_STORAGE_TO_DELIVER_ERROR,
    payload: { error },
  };
};

export const resellGold = (payload) => {
  return {
    type: RESELL_GOLD_START,
    payload: payload,
  };
};

export const resellGoldSuccess = (data) => {
  return {
    type: RESELL_GOLD_SUCCESS,
    payload: data,
  };
};

export const resellGoldError = (error) => {
  return {
    type: RESELL_GOLD_ERROR,
    payload: { error },
  };
};

export const clearGoldInventoryTransactions = () => {
  return {
    type: CLEAR_GOLD_INVENTORY_TRANSACTIONS,
  };
};