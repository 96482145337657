import {
  ADD_PRODUCT_ADDRESS_ERROR,
  ADD_PRODUCT_ADDRESS_START,
  ADD_PRODUCT_ADDRESS_SUCCESS,
  DELETE_PRODUCT_ADDRESS_ERROR,
  DELETE_PRODUCT_ADDRESS_START,
  DELETE_PRODUCT_ADDRESS_SUCCESS,
  EDIT_PRODUCT_ADDRESS_ERROR,
  EDIT_PRODUCT_ADDRESS_START,
  EDIT_PRODUCT_ADDRESS_SUCCESS,
  FETCH_PRODUCT_ADDRESS_ERROR,
  FETCH_PRODUCT_ADDRESS_START,
  FETCH_PRODUCT_ADDRESS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  addresses: [],
  editAddress: {
    loading: false,
  },
  deleteAddress: {
    loading: false,
  },
  clearingCounter: 0,
};
const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_ADDRESS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_PRODUCT_ADDRESS_SUCCESS:
      state = {
        ...state,
        loading: false,
        addresses: action.payload.docs,
      };
      break;
    case FETCH_PRODUCT_ADDRESS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case ADD_PRODUCT_ADDRESS_START:
      state = {
        ...state,
        editAddress: {
          loading: true,
        },
      };
      break;
    case ADD_PRODUCT_ADDRESS_SUCCESS:
      state = {
        ...state,
        editAddress: {
          loading: false,
        },
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case ADD_PRODUCT_ADDRESS_ERROR:
      state = {
        ...state,
        editAddress: {
          loading: false,
        },
      };
      break;
    case EDIT_PRODUCT_ADDRESS_START:
      state = {
        ...state,
        editAddress: {
          loading: true,
        },
      };
      break;
    case EDIT_PRODUCT_ADDRESS_SUCCESS:
      state = {
        ...state,
        editAddress: {
          loading: false,
        },
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case EDIT_PRODUCT_ADDRESS_ERROR:
      state = {
        ...state,
        editAddress: {
          loading: false,
        },
      };
      break;
    case DELETE_PRODUCT_ADDRESS_START:
      state = {
        ...state,
        deleteAddress: {
          loading: true,
        },
      };
      break;
    case DELETE_PRODUCT_ADDRESS_SUCCESS:
      state = {
        ...state,
        deleteAddress: {
          loading: false,
        },
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case DELETE_PRODUCT_ADDRESS_ERROR:
      state = {
        ...state,
        deleteAddress: {
          loading: false,
        },
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default addressReducer;
