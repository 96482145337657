import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { useTranslation, withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import Loader from "components/Common/Loader";
import {
  fetchWallets,
  toggleCurrentModal
} from "store/actions";
import CustomTable from "components/Common/CustomTable";

function Inventory(props) {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { wallets, loading } = useSelector(state => state.walletReducer);
  const { subPortal } = useSelector(state => state.Layout);

  useEffect(() => {
    dispatch(fetchWallets({
      isDemo: subPortal === "DEMO",
    }));
  }, []);

  const columns = [
    {
      dataField: "type",
      text: t("Type"),
      formatter: (val) => (<>{val?.asset}</>),
    },
    {
      dataField: "type",
      text: t("Title"),
      formatter: (val) => (<>{val?.product?.title}</>),
    },
    {
      dataField: "qty",
      text: t("Total Quantity"),
      formatter: (item) => item.amount,
    },
    {
      dataField: "fQty",
      text: t("Under Process Quantity"),
      formatter: (item) => item?.freezeAmount,
    },
    {
      dataField: "sellPrice",
      text: t("Sell Price"),
      formatter: (item) => (item?.product?.sellPrice || "Not Available"),
    },
    {
      dataField: "amount",
      text: t("Action"),
      formatter: (item) =>
        <div className="">
          <Button
            className="border-0 btn-sm mx-2" color="primary"
            onClick={() => dispatch(toggleCurrentModal("deliverFromStorage", item))}
          >
            {t("Deliver")}
          </Button>
          <Button
            className="border-0 btn-sm mx-2" color="primary"
            onClick={() => dispatch(toggleCurrentModal("resellGold", item))}
          >
            {t("Sell Back")}
          </Button>
        </div>
    },
  ];

  const inventoryRows = wallets.filter((item) => item.isInventory);


  return (
    <>
      <div className="page-content" style={{
        position: "relative",
      }}>
        <MetaTags>
          <title>{props.t("Inventory")}</title>
        </MetaTags>
        <Container className="mb-5 mt-5">
          <div className="d-flex flex-row">
            <h1 className="mb-4">
              {props.t("Inventory")}
            </h1>
          </div>
          <CardWrapper className="glass-card shadow mb-5">
            {
              (loading) ? <div className="d-flex justify-content-center">
                <Loader />
              </div> : <div>
                <CustomTable
                  rows={inventoryRows?.filter(row => row?.amount !== 0 || row?.freezeAmount !== 0 || row?.product?.isCp)}
                  columns={columns}
                  loading={loading}
                  customMessage = {t("No Inventory Available")}
                />
              </div>
            }
          </CardWrapper>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(Inventory); 