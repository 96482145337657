import React from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Button, Col, Row
} from "reactstrap";

function GoldPageHeader(props) {
  const { t } = useTranslation();
  const { title } = props;
  return (
    <>
      <MetaTags>
        {t(title)}
      </MetaTags>
      <Row className="d-flex justify-content-between">
        <Col>
          <div className="d-flex justify-content-end">
            <>
              <Button color="primary"  className="mx-3 btn btn-light shadow border border-0 color-white" onClick={() => window.location.replace("/legal-document")}
              >
                {t("Legal Documents")}
              </Button>
            </>
          </div>
        </Col>
      </Row>
    </>);
}

export default GoldPageHeader;
