import qs from "qs";
import * as axiosHelper from "../api_helper";

export const getGold = async({ payload }) => {
  const data = await axiosHelper.get(`/gold?${qs.stringify(payload)}`);
  return data.result;
};

export const addGold = async(payload) => {
  const data = await axiosHelper.post("/gold/transactions", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editGold = async({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/gold/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const deleteGold = async({ payload }) => {
  const data = await axiosHelper.del(`/gold/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const getGoldOrders = async({ payload }) => {
  const data = await axiosHelper.get(`/gold/transactions?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const getGoldDeposits = async({ payload }) => {
  const data = await axiosHelper.get(`/transactions/deposit?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const getGoldWithdrawals = async({ payload }) => {
  const data = await axiosHelper.get(`/transactions/withdraw?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const getCustomerAddress = async({ payload }) => {
  const data = await axiosHelper.get(`/gold/addresses?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const addCustomerAddress = async(payload) => {
  const data = await axiosHelper.post("/gold/addresses", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editCustomerAddress = async( payload ) => {
  const { id, values } = payload;
  values.isEdit = true;
  const data = await axiosHelper.patch(`/gold/addresses/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const deleteCustomerAddress = async({ payload }) => {
  const { id } = payload;
  const data = await axiosHelper.patch(`/gold/addresses/${id}`, {
    isEdit: false
  });
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const requestStorageForDelivery = async(payload) => {
  const data = await axiosHelper.post("/gold/transactions/request-delivery", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const resellGold = async(payload) => {
  const data = await axiosHelper.post("/gold/transactions/resell", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const getGoldDeliveryFees = async({ payload }) => {
  const data = await axiosHelper.get(`/gold/delivery-fees?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};