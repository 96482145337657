import {
  BUY_GOLD_START,
  BUY_GOLD_SUCCESS,
  BUY_GOLD_ERROR,
  BUY_GOLD_CLEAR,
  FETCH_GOLD_ORDERS_START,
  FETCH_GOLD_ORDERS_SUCCESS,
  FETCH_GOLD_ORDERS_ERROR,
  REQUEST_STORAGE_TO_DELIVER_START,
  REQUEST_STORAGE_TO_DELIVER_SUCCESS,
  REQUEST_STORAGE_TO_DELIVER_ERROR,
  RESELL_GOLD_START,
  RESELL_GOLD_SUCCESS,
  RESELL_GOLD_ERROR,
  CLEAR_GOLD_INVENTORY_TRANSACTIONS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  orders: [],
  ordersPagination: {},
  transaction: {
    loading: false,
    success: false,
    clear: 0,
  },
  inventory: {
    loading: false,
    success: false,
    error: "",
    clearingCounter: 0,
  }
};
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUY_GOLD_START:
      state = {
        ...state,
        transaction: {
          ...state.transaction,
          loading: true,
        }
      };
      break;
    case BUY_GOLD_SUCCESS: 
      state = {
        ...state,
        transaction: {
          ...state.transaction,
          loading: false,
          success: true,
        }
      };
      break;
    case BUY_GOLD_ERROR:
      state = {
        ...state,
        transaction: {
          ...state.transaction,
          loading: false,
          success: false,
        }
      };
      break;
    case BUY_GOLD_CLEAR:
      state = {
        ...state,
        transaction: {
          ...state.transaction,
          clear: state.transaction.clear + 1,
        }
      };
      break;
    case FETCH_GOLD_ORDERS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_GOLD_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: [...action.payload.docs],
        ordersPagination: {
          docs: undefined,
          ...action.payload
        },
      };
      break;
    case FETCH_GOLD_ORDERS_ERROR: 
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case RESELL_GOLD_START:
    case REQUEST_STORAGE_TO_DELIVER_START:
      state = {
        ...state,
        inventory: {
          ...state.inventory,
          loading: true,
        }
      };
      break;
    case RESELL_GOLD_SUCCESS:
    case REQUEST_STORAGE_TO_DELIVER_SUCCESS:
      state = {
        ...state,
        inventory: {
          ...state.inventory,
          success: true,
          loading: false,
          clearingCounter: state.inventory.clearingCounter + 1,
        }
      };
      break;
    case RESELL_GOLD_ERROR:
    case REQUEST_STORAGE_TO_DELIVER_ERROR:
      state = {
        ...state,
        inventory: {
          ...state.inventory,
          loading: false,
          success: false,
          error: action.payload.error,
        }
      };
      break;
    case CLEAR_GOLD_INVENTORY_TRANSACTIONS:
      state = {
        ...state,
        inventory: {
          ...state.inventory,
          success: false,
          error: "",
        }
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default orderReducer;
