import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";


import {
  fetchDeliveryFeesError,
  fetchDeliveryFeesSuccess
} from "./actions";
import {
  FETCH_DELIVERY_FEES_START
} from "./actionTypes";
import { getGoldDeliveryFees } from "apis/gold";

function* fetchDeliveryFees(params = {}) {
  try {
    const data = yield call(getGoldDeliveryFees, params);
    yield put(fetchDeliveryFeesSuccess(data));
  } catch (error) {
    yield put(fetchDeliveryFeesError(error));
  }
}

function* goldSaga() {
  yield takeEvery(FETCH_DELIVERY_FEES_START, fetchDeliveryFees);
}

export default goldSaga;
