import {
  ADD_PRODUCT_ADDRESS_ERROR,
  ADD_PRODUCT_ADDRESS_START,
  ADD_PRODUCT_ADDRESS_SUCCESS,
  DELETE_PRODUCT_ADDRESS_ERROR,
  DELETE_PRODUCT_ADDRESS_START,
  DELETE_PRODUCT_ADDRESS_SUCCESS,
  EDIT_PRODUCT_ADDRESS_ERROR,
  EDIT_PRODUCT_ADDRESS_START,
  EDIT_PRODUCT_ADDRESS_SUCCESS,
  FETCH_PRODUCT_ADDRESS_ERROR,
  FETCH_PRODUCT_ADDRESS_START,
  FETCH_PRODUCT_ADDRESS_SUCCESS
} from "./actionTypes";

export const fetchAddresses = (params = {} ) => {
  return {
    type: FETCH_PRODUCT_ADDRESS_START,
    payload: params,
  };
};

export const fetchAddressesSuccess = (data) => {
  return {
    type: FETCH_PRODUCT_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const fetchAddressesError = (error) => {
  return {
    type: FETCH_PRODUCT_ADDRESS_ERROR,
    payload: { error },
  };
};

export const addProductAddress = (data) => {
  return {
    type: ADD_PRODUCT_ADDRESS_START,
    payload: data,
  };
};

export const addProductAddressSuccess = (data) => {
  return {
    type: ADD_PRODUCT_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const addProductAddressError = (error) => {
  return {
    type: ADD_PRODUCT_ADDRESS_ERROR,
    payload: { error },
  };
};

export const editProductAddress = (data) => {
  return {
    type: EDIT_PRODUCT_ADDRESS_START,
    payload: data,
  };
};

export const editProductAddressSuccess = (data) => {
  return {
    type: EDIT_PRODUCT_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const editProductAddressError = (error) => {
  return {
    type: EDIT_PRODUCT_ADDRESS_ERROR,
    payload: { error },
  };
};

export const deleteProductAddress = (data) => {
  return {
    type: DELETE_PRODUCT_ADDRESS_START,
    payload: data,
  };
};

export const deleteProductAddressSuccess = (data) => {
  return {
    type: DELETE_PRODUCT_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const deleteProductAddressError = (error) => {
  return {
    type: DELETE_PRODUCT_ADDRESS_ERROR,
    payload: { error },
  };
};
