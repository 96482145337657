import {
  FETCH_GOLD_DEPOSITS_ERROR,
  FETCH_GOLD_DEPOSITS_START,
  FETCH_GOLD_DEPOSITS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  deposits: [],
  depositsPagination: {},
  transaction: {
    loading: false,
    success: false,
    clear: false,
  },
};
const depositsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOLD_DEPOSITS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_GOLD_DEPOSITS_SUCCESS:
      state = {
        ...state,
        loading: false,
        deposits: action.payload.docs,
        depositsPagination: {
          ...action.payload,
          docs: undefined
        },
      };
      break;
    case FETCH_GOLD_DEPOSITS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default depositsReducer;
