import {
  REGISTER_LIVE_USER,
  REGISTER_LIVE_USER_SUCCESSFUL,
  REGISTER_LIVE_USER_FAILED,
  REGISTER_DEMO_USER,
  REGISTER_DEMO_USER_SUCCESSFUL,
  REGISTER_DEMO_USER_FAILED,
  REGISTER_FOREX_LIVE_USER_REQUESTED,
  REGISTER_FOREX_LIVE_USER_SUCCESS,
  REGISTER_FOREX_LIVE_USER_FAIL,
  REGISTER_FOREX_DEMO_USER_REQUESTED,
  REGISTER_FOREX_DEMO_USER_SUCCESS,
  REGISTER_FOREX_DEMO_USER_FAIL,
  REGISTER_FOREX_IB_USER_REQUESTED,
  REGISTER_FOREX_IB_USER_SUCCESS,
  REGISTER_FOREX_IB_USER_FAIL,

  // money management
  REGISTER_MM_USER_REQUESTED,
  REGISTER_MM_USER_SUCCESS,
  REGISTER_MM_USER_FAIL,
  
  REGISTER_GOLD_LIVE_USER_REQUESTED,
  REGISTER_GOLD_LIVE_USER_SUCCESS,
  REGISTER_GOLD_LIVE_USER_FAIL,
  REGISTER_GOLD_DEMO_USER_REQUESTED,
  REGISTER_GOLD_DEMO_USER_SUCCESS,
  REGISTER_GOLD_DEMO_USER_FAIL,

  REGISTER_INVESTOR_USER_REQUESTED,
  REGISTER_INVESTOR_USER_SUCCESS,
  REGISTER_INVESTOR_USER_FAIL,
  REGISTER_SP_USER_REQUESTED,
  REGISTER_SP_USER_SUCCESS,
  REGISTER_SP_USER_FAIL,
} from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_LIVE_USER:
    case REGISTER_DEMO_USER:
    case REGISTER_FOREX_LIVE_USER_REQUESTED:
    case REGISTER_FOREX_DEMO_USER_REQUESTED:
    case REGISTER_FOREX_IB_USER_REQUESTED:
    case REGISTER_MM_USER_REQUESTED:
    case REGISTER_GOLD_LIVE_USER_REQUESTED:
    case REGISTER_GOLD_DEMO_USER_REQUESTED:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      };
      break;
    case REGISTER_LIVE_USER_SUCCESSFUL:
    case REGISTER_DEMO_USER_SUCCESSFUL:
    case REGISTER_FOREX_LIVE_USER_SUCCESS:
    case REGISTER_FOREX_DEMO_USER_SUCCESS:
    case REGISTER_FOREX_IB_USER_SUCCESS:
    case REGISTER_MM_USER_SUCCESS:
    case REGISTER_GOLD_LIVE_USER_SUCCESS:
    case REGISTER_GOLD_DEMO_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null
      };
      break;
    case REGISTER_LIVE_USER_FAILED:
    case REGISTER_DEMO_USER_FAILED:
    case REGISTER_FOREX_LIVE_USER_FAIL:
    case REGISTER_FOREX_DEMO_USER_FAIL:
    case REGISTER_FOREX_IB_USER_FAIL:
    case REGISTER_MM_USER_FAIL:
    case REGISTER_GOLD_LIVE_USER_FAIL:
    case REGISTER_GOLD_DEMO_USER_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };
      break;

    // forex sp
    case REGISTER_SP_USER_REQUESTED:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      };
      break;
    case REGISTER_SP_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      };
      break;
    case REGISTER_SP_USER_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };
      break;

    // investor
    case REGISTER_INVESTOR_USER_REQUESTED:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      };
      break;
    case REGISTER_INVESTOR_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      };
      break;
    case REGISTER_INVESTOR_USER_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
