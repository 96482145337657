import {
  FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED,
  FETCH_CLIENT_MM_WITHDRAWALS_SUCCESS,
  FETCH_CLIENT_MM_WITHDRAWALS_FAIL,

  UNSUBSCRIBE_STRATEGY,
  UNSUBSCRIBE_STRATEGY_SUCCESS,
  UNSUBSCRIBE_STRATEGY_FAIL,
} from "./actionTypes";

// fetch client withdrawals 
export const fetchClientWithdrawals = (params = {}) => {
  return {
    type: FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED,  
    payload: params
  };
};
export const fetchClientWithdrawalsSuccess = (data) => {
  return {
    type: FETCH_CLIENT_MM_WITHDRAWALS_SUCCESS,
    payload: data
  };
};
export const fetchClientWithdrawalsFail = (error) => {
  return {
    type: FETCH_CLIENT_MM_WITHDRAWALS_FAIL,
    payload: { error }
  };
};

export const unsubStrategy = (payload) => {
  return {
    type: UNSUBSCRIBE_STRATEGY,
    payload,
  };
};
export const unsubStrategySuccess = (params = {}) => {
  return {
    type: UNSUBSCRIBE_STRATEGY_SUCCESS,
    payload: params,
  };
};
export const unsubStrategyFail = (error) => {
  return {
    type: UNSUBSCRIBE_STRATEGY_FAIL,
    payload: { error }
  };
};