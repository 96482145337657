import {
  FETCH_GOLD_DEPOSITS_ERROR,
  FETCH_GOLD_DEPOSITS_START,
  FETCH_GOLD_DEPOSITS_SUCCESS
} from "./actionTypes";

export const fetchGoldDeposits = (params = {} ) => {
  return {
    type: FETCH_GOLD_DEPOSITS_START,
    payload: params,
  };
};

export const fetchGoldDepositsSuccess = (data) => {
  return {
    type: FETCH_GOLD_DEPOSITS_SUCCESS,
    payload: data,
  };
};

export const fetchGoldDepositsError = (error) => {
  return {
    type: FETCH_GOLD_DEPOSITS_ERROR,
    payload: { error },
  };
};