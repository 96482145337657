import { combineReducers } from "redux";

import deposit from "./transactions/deposit/reducer";
import withdrawal from  "./transactions/withdrawal/reducer";
import strategy from  "./strategy/reducer";

const mmReducer = combineReducers({
  deposit,
  withdrawal,
  strategy
});

export default mmReducer;