import { useEffect } from "react";
import {
  Col,
  Container,
  Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GoldCard from "./GoldCard";
import {
  fetchGold,
  toggleCurrentModal
} from "store/actions";
import Loader from "components/Common/Loader";
export default function GoldOptions() {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  let baseCurrency = JSON.parse(localStorage.getItem("GOLD_BASE_CURRENCY"))?.symbol;
  const { gold, loading } = useSelector((state) => state.goldReducer.products);
  const transaction = useSelector((state) => state.goldReducer.orders.transaction);
  const { conversionRate } = useSelector((state) => state.conversionReducer);
  useEffect(() => {
    if (!baseCurrency) {
      const defaultCurrency = {
        symbol: "USD",
      };
      localStorage.setItem("GOLD_BASE_CURRENCY", JSON.stringify(defaultCurrency));
    }
    dispatch(fetchGold({
      baseCurrency: baseCurrency || "USD",
    }));
  }, []);

  useEffect(() => {
    if (transaction?.clear) {
      dispatch(toggleCurrentModal(""));
    }
  }, [transaction?.clear]);

  return (
    <Container>
      <h1 className="mb-2">{t("Products")}</h1>
      <p className="text-muted mb-4">{t("Select from a variety of products")}</p>
      {
        loading ? <div className="text-center"> <Loader/> </div> : (
          <>
            <Row>
              { gold.filter(g => g.isEnable && (g?.assetId?.symbol === "GOLD")).map((option) => 
                <Col key={option._id} lg={4} xl={3} md={6} xs={12} className="mb-4">
                  <GoldCard option={option} t={t} conversionRate={conversionRate}  />
                </Col>
              )
              }
            </Row>
            <Row>
              {gold.filter(g => g.isEnable && (g?.assetId?.symbol === "SILVER")).map((option) => 
                <Col key={option._id} lg={4} xl={3} md={6} xs={12} className="mb-4">
                  <GoldCard option={option} t={t} conversionRate={conversionRate} />
                </Col>
              )
              }
            </Row>
          </>
        )

      }
    </Container>
  );
}
