import React, {
  lazy,
  useEffect,
  useState
} from "react";
import {
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import MetaTags from "react-meta-tags";
import Widgets from "../../../components/Common/Widgets";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { withTranslation, useTranslation } from "react-i18next";
import GoldOptions from "./GoldOptions";
import CardWrapper from "components/Common/CardWrapper";
import Deposits from "./Deposits";
import Withdrawals from "./Withdrawals";
import Orders from "./Orders";
import GoldJourney from "components/Journey/GoldJourney";
import { CUSTOMER_SUB_PORTALS } from "common/constants";
import {
  createTradingPortalAccessRequest,
  fetchProfile,
  requestChangeDefaultSubPortal
} from "store/actions";
import GoldPageHeader from "components/Forex/Common/GoldPageHeader";
const Slider = lazy(() => import("./Slider"));

const GoldDashboard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const { subPortal, portal } = useSelector(state => state.Layout);
  const isDemo = subPortal === CUSTOMER_SUB_PORTALS.DEMO;
  const { clientData } = useSelector(state => state.Profile);

  useEffect(() => {

    dispatch(fetchProfile({
      history: props.history
    }));
  }, [subPortal]);

  const tabs = isDemo ? ["Orders"] : ["Deposits", "Withdrawals", "Orders"];
  const tabContent = isDemo ? [<Orders key={"orders"} />] : [<>
    {
      activeTab === 0 && <Deposits key={"deposits"} />
    }
    {
      activeTab === 1 && <Withdrawals key={"withdrawals"} />
    }
    {
      activeTab === 2 && <Orders key={"orders"} />
    }
  </>];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Gold Dashboard")}</title>
        </MetaTags>
        
        <Row className="dashboard forex-dashboard">
          <Col xs={12} xl={12}>
            <Container>
              {
                clientData?.gold?.isDemo && !clientData?.gold?.isClient && <CardWrapper className="my-3 d-flex flex-row justify-content-around">
                  <h6>Your account is currently a demo account.</h6>
                  <Button color="primary"  className="border-0 btn-sm" onClick={() => {
                    dispatch(requestChangeDefaultSubPortal({
                      subPortal: CUSTOMER_SUB_PORTALS.LIVE,
                      portal
                    }));
                  }}>Go Live</Button>
                </CardWrapper>
              }
              {
                (!clientData?.fx?.isClient && !clientData?.fx?.isRequested && !clientData?.fx?.isIb && clientData?.stages?.kycApproved)  && <CardWrapper color="dark" className="my-3 rounded-2 d-flex flex-row justify-content-between shadow align-items-center">
                  <h6 className="" >{t("Trading Access Request")}</h6>
                  <Button color="primary" className="waves-effect waves-light" onClick={() => {
                    dispatch(createTradingPortalAccessRequest({}));
                    dispatch(fetchProfile({
                      history: props.history
                    })
                    );
                  }}>{t("Request")}</Button>
                </CardWrapper>
              }
              {clientData?.gold?.isDemo && clientData?.gold?.isClient && <>
                <FormGroup check className="d-flex flex-rows mt-3 m-2 cursor-pointer" >
                  <Input
                    name="radio2"
                    type="radio"
                    id="live"
                    className="me-2"
                    checked={subPortal === CUSTOMER_SUB_PORTALS.LIVE}
                    onClick={() => {
                      dispatch(requestChangeDefaultSubPortal({
                        subPortal: CUSTOMER_SUB_PORTALS.LIVE,
                        portal
                      }));
                    }}
                  />
                  <Label check for="ib">
                    {t("Live")}
                  </Label>
                  <FormGroup check className="my-auto mx-3 " >
                    <Input
                      name="radio2"
                      type="radio"
                      id="demo"
                      className="me-2"
                      checked={subPortal === CUSTOMER_SUB_PORTALS.DEMO}
                      onClick={() => {
                        dispatch(requestChangeDefaultSubPortal({
                          subPortal: CUSTOMER_SUB_PORTALS.DEMO,
                          portal
                        }));
                      }}
                    />
                    <Label check for="ib" >
                      {t("Demo")}
                    </Label>
                  </FormGroup>
                </FormGroup>
              </>}
              <GoldPageHeader title="Gold Dashboard" portal="GOLD"></GoldPageHeader>  
              <div className="pt-3">
                {<Slider />}
              </div>
              <div className="pt-4">
                <GoldJourney history={props.history} />
                <GoldOptions />
                <div className="py-5">
                  <Widgets
                    tabs={tabs}
                    className="mb-3"
                    setTab={setActiveTab}
                  >
                    <CardWrapper>
                      {tabContent}
                    </CardWrapper>
                  </Widgets>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  marketsLoading: state.crypto.markets.loading || false,
  markets: state.crypto.markets.markets || [],
  marketNames: state.crypto.markets.marketNames,
  profile: state.Profile.clientData,
});

export default connect(mapStateToProps, null)(withTranslation()(GoldDashboard));
