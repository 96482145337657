import {
  FETCH_DELIVERY_FEES_ERROR,
  FETCH_DELIVERY_FEES_START,
  FETCH_DELIVERY_FEES_SUCCESS
} from "./actionTypes";

export const fetchDeliveryFees = (params) => {
  return {
    type: FETCH_DELIVERY_FEES_START,
    payload: params,
  };
};

export const fetchDeliveryFeesSuccess = (data) => {
  return {
    type: FETCH_DELIVERY_FEES_SUCCESS,
    payload: data,
  };
};

export const fetchDeliveryFeesError = (error) => {
  return {
    type: FETCH_DELIVERY_FEES_ERROR,
    payload: error,
  };
};
