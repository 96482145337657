import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import { FETCH_STRATEGY_REQUESTED } from "./actionTypes";
import {
  fetchStrategiesSuccess, fetchStrategiesFail,
} from "./actions";
import { getStrategies } from "apis/mm/strategy";

function * fetchStrategies(params){
  try {
    const data = yield call(getStrategies, params.payload);
    yield put(fetchStrategiesSuccess(data));
  } catch (error){ 
    yield put(fetchStrategiesFail({ error: error.message }));
  }
}
  
function * depositSaga(){
  yield takeEvery(FETCH_STRATEGY_REQUESTED, fetchStrategies);
}
  
export default depositSaga;