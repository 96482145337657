import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedTransactions from "../Shared/SharedTransactions";
import { useTranslation } from "react-i18next";
import { fetchGoldOrders } from "store/actions";
import moment from "moment";

export default function Deposits() {
  const { t } = useTranslation();
  const { loading, orders = [] } = useSelector((state) => state.goldReducer.orders);
  const { subPortal } = useSelector((state) => state.Layout);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGoldOrders({
      isDemo: subPortal === "DEMO",
    }));
  }, []);

  const columns = [
    {
      dataField: "createdAt",
      text: t("Order Date"),
      formatter: (val) => <span>{moment(val.createdAt).format("DD-MM-YYYY")}</span>,
    },
    {
      dataField: "type",
      text: t("Title"),
      formatter: (val) => (<>{val?.products?.title}</>),
    },
    {
      dataField: "currency",
      text: t("Currency Used"),
      formatter: (val) => (val.currency),
    },
    {
      dataField: "qty",
      text: t("Quantity"),
      formatter: (item) => item.quantity
    },
    {
      dataField: "amount",
      text: t("Amount"),
      formatter: (item) =>
        parseFloat(item?.amount?.$numberDecimal)?.toFixed(2) || parseFloat(item?.amount)?.toFixed(2) || "-",
    },
    {
      dataField: "delivery",
      text: t("Order Type"),
      formatter: (val) => (val?.type?.split("_").join(" ") || "-"),
    },
  ];
  return (
    <SharedTransactions data={orders} loading={loading} t={t} columns={columns} />
  );
}
