import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  BUY_GOLD_START, FETCH_GOLD_ORDERS_START, REQUEST_STORAGE_TO_DELIVER_START, RESELL_GOLD_START,
} from "./actionTypes";
import {
  buyGoldSuccess,
  buyGoldError,
  buyGoldClear,
  fetchGoldOrdersSuccess,
  fetchGoldOrdersError,
  requestStorageToDeliverSuccess,
  requestStorageToDeliverError,
} from "./actions";

//Include Both Helper File with needed methods
import * as goldApi from "../../../apis/gold/gold";
import { showErrorNotification, showSuccessNotification } from "store/general/notifications/actions";

function* addGold({ payload }) {
  try {
    const data = yield call(goldApi.addGold, payload);
    yield put(buyGoldSuccess(data));
    yield put(showSuccessNotification("Product successfully bought"));
    yield put(buyGoldClear());
  } catch (error) {
    yield put(buyGoldError("Error Occurred"));
    yield put(showErrorNotification(`Error Occurred: ${error ? error.message : "Cannot buy Product"}`));
  }
}

function* fetchGoldOrders(params = {}) {
  try {
    const data = yield call(goldApi.getGoldOrders, params);
    yield put(fetchGoldOrdersSuccess(data));
  } catch (error) {
    yield put(fetchGoldOrdersError(error));
  }
}

function* storageToDeliveryRequest({ payload }) {
  try {
    const data = yield call(goldApi.requestStorageForDelivery, payload);
    yield put(requestStorageToDeliverSuccess(data));
    yield put(showSuccessNotification("Request successfully sent"));
  } catch (error) {
    yield put(requestStorageToDeliverError("Error Occurred"));
    yield put(showErrorNotification(`Error Occurred: ${error ? error.message : "Cannot send request"}`));
  }
}

function* resellGoldRequest({ payload }) {
  try {
    const data = yield call(goldApi.resellGold, payload);
    yield put(requestStorageToDeliverSuccess(data));
    yield put(showSuccessNotification("Request successfully sent"));
  } catch (error) {
    yield put(requestStorageToDeliverError("Error Occurred"));
    yield put(showErrorNotification(`Error Occurred: ${error ? error.message : "Cannot send request"}`));
  }
}

function* ordersSaga() {
  yield takeEvery(BUY_GOLD_START, addGold);
  yield takeEvery(FETCH_GOLD_ORDERS_START, fetchGoldOrders);
  yield takeEvery(REQUEST_STORAGE_TO_DELIVER_START, storageToDeliveryRequest);
  yield takeEvery(RESELL_GOLD_START, resellGoldRequest);
}

export default ordersSaga;
