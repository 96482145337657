import CardWrapper from "components/Common/CardWrapper";
import CustomTable from "components/Common/CustomTable";
import Loader from "components/Common/Loader";

export default function SharedWalletCard(props) {
  const { title, columns, data, loading, t } = props;
  return (
    <>
      <h1 className="mb-3">{t(title)}</h1>
      <CardWrapper className="mb-5 glass-card">
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <CustomTable columns={columns} rows={data} />
        )}
      </CardWrapper>
    </>
  );
}
