import React, { useState } from "react";
import CardWrapper from "components/Common/CardWrapper";
import DeleteModal from "components/Common/DeleteModal";

// import Loader from "components/Common/Loader";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { startCase } from "lodash";
import { toggleCurrentModal, unsubStrategy } from "store/actions";

const details = {
  investmentIn: "Stocks",
  risk: "10%",
  apy: "~5%"
};

const StrategyCard = ({ strategy, clientSubscription, t }) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);

  // strategyId in the response payload doesn't get populated immediately
  const isSubscribed = (strategyId) => clientSubscription?.some((sub) =>
    sub.isSubscribed && sub.status !== "REJECTED" && (sub?.strategyId?._id == strategyId || sub?.strategyId == strategyId));

  const currentSub = (strategyId) => clientSubscription?.find((sub) =>
    sub.isSubscribed && (sub?.strategyId?._id == strategyId || sub?.strategyId == strategyId));

  const handelDelete = () => {
    dispatch(unsubStrategy({
      strategyId: strategy._id,
      depositId: currentSub(strategy._id)._id,
    }));
    setDeleteModal(false);
  };

  return (
    <CardWrapper>
      <Container style={{ textAlign: "center" }}>
        <Row className="mb-3">
          <Col>
            <h1 className="mb-2">{t(`${strategy.name}`)}</h1>
          </Col>
        </Row>
        {/* <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Risk")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{strategy?.risk || "-"}</h5>
            </span>
          </Col>
        </Row> */}
        {
          strategy?.details && Object.keys(strategy.details).map((keys) => {
            return (
              <Row className="mb-3 strategy-row" key={keys}>
                <Col className="strategy-col">
                  <span className="strategy-span">{t(`${startCase(keys)}`)}:</span>
                </Col>
                <Col className="strategy-col" lg={6}>
                  <span className="strategy-span">
                    <h5>{t(`${strategy.details[keys] || "-"}`)}</h5>
                  </span>
                </Col>
              </Row>
            );
          })
        }
        {/* <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Min Deposit")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{t(`${strategy.minDeposit}`)}</h5>
            </span>
          </Col>
        </Row> */}
        {/* <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Current Deposits")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{strategy?.currentDeposits?.$numberDecimal || 0}</h5>
            </span>
          </Col>
        </Row> */}
        {/*         
        <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Monthly")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{strategy?.monthlyReturns || "-"}</h5>
            </span>
          </Col>
        </Row>
        <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Yearly")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{strategy?.yearlyReturns || "-"}</h5>
            </span>
          </Col>
        </Row>
        <Row className="mb-3 strategy-row">
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">{t("Guarentee")}:</span>
          </Col>
          <Col className="strategy-col" lg={6}>
            <span className="strategy-span">
              <h5>{strategy?.guarenteedReturns || "-"}</h5>
            </span>
          </Col>
        </Row> */}
        <Row className="mb-3">
          <Col className="strategy-col d-flex justify-content-evenly" lg={12}>
            {<Button color="success" onClick={() => { dispatch(toggleCurrentModal("BuyGoldSuccess", strategy)) }}>{t("Buy")}</Button>}
          </Col>
        </Row>
      </Container>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handelDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
    </CardWrapper>
  );
};

export default withTranslation()(StrategyCard); 