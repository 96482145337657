import { all, fork } from "redux-saga/effects";

import ordersSaga from "./orders/saga";
import depositsSaga from "./deposits/saga";
import productsSaga from "./products/saga";
import withdrawalsSaga from "./withdrawals/saga";
import addressSaga from "./address/saga";
import deliveryFeesSaga from "./deliveryFees/saga";

export default function* goldSaga() {
  yield all([
    fork(ordersSaga),
    fork(depositsSaga),
    fork(productsSaga),
    fork(withdrawalsSaga),
    fork(addressSaga),
    fork(deliveryFeesSaga)
  ]);
}