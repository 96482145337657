import { lazy } from "react";
const Logout = lazy(() => import("pages/Authentication/Logout"));
const Login = lazy(() => import("pages/Authentication/Login"));
const ForgetPwd = lazy(() => import("pages/Authentication/ForgetPassword"));
const ResetPassword = lazy(() => import("pages/Authentication/ResetPassword"));
// const CryptoDemoRegister = lazy(() => import("pages/Authentication/Register/Crypto/CryptoDemoRegister"));
// const CryptoLiveRegister = lazy(() => import("pages/Authentication/Register/Crypto/CryptoLiveRegister"));
// const ForexDemoRegister = lazy(() => import("pages/Authentication/Register/Forex/ForexDemoRegister"));
// const ForexLiveRegister = lazy(() => import("pages/Authentication/Register/Forex/ForexLiveRegister"));
// const ForexIbRegister = lazy(() => import("pages/Authentication/Register/Forex/ForexIbRegister"));
// const mmRegister = lazy(() => import("pages/Authentication/Register/MoneyManagement"));
const goldRegister = lazy(() => import("pages/Authentication/Register/Gold"));
const Page404 = lazy(() => import("pages/Authentication/Page404"));
import CampaignUnsubscribe from "pages/campaignUnsubscribe";
import ForexSpRegister from "pages/Authentication/Register/Forex/ForexSpRegister";
import ForexInvestorRegister from "pages/Authentication/Register/Forex/ForexInvestorRegister";

export default [
  //non Auth routes
  {
    path: "/logout",
    component: Logout,
    isAuth: false,
  },
  {
    path: "/login",
    component: Login,
    isAuth: false,
  },
  {
    path: "/forgot-password",
    component: ForgetPwd,
    isAuth: false,
  },
  // {
  //   path: "/register/crypto/live",
  //   component: CryptoLiveRegister,
  //   isAuth: false 
  // },
  // {
  //   path: "/register/crypto/demo",
  //   component: CryptoDemoRegister,
  //   isAuth: false 
  // },
  // {
  //   path: "/register/forex/live",
  //   component: ForexLiveRegister,
  //   isAuth: false 
  // },
  // {
  //   path: "/register/forex/demo",
  //   component: ForexDemoRegister,
  //   isAuth: false 
  // },
  // {
  //   path: "/register/forex/ib",
  //   component: ForexIbRegister,
  //   isAuth: false 
  // },
  // {
  //   path: "/register/mm/live",
  //   component: mmRegister,
  //   isAuth: false 
  // },
  {
    path: "/register/gold/live",
    component: goldRegister,
    isAuth: false 
  },
  {
    path: "/register/gold/demo",
    component: goldRegister,
    isAuth: false 
  },
  {
    path:"/reset-password",
    component:ResetPassword,
    isAuth: false
  },
  {
    path: "/unsubscribe",
    component: CampaignUnsubscribe,
    isAuth: false,
  },
  {
    path: "*",
    exact: true,
    component: Page404,
    isAuth: false,
  },
];
