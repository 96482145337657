import {
  FETCH_STRATEGY_REQUESTED,
  FETCH_STRATEGY_SUCCESS,
  FETCH_STRATEGY_FAIL,
} from "./actionTypes";

// fetch client deposits
export const fetchStrategies = (params = {}) => {
  return {
    type: FETCH_STRATEGY_REQUESTED,  
    payload: params
  };
};
export const fetchStrategiesSuccess = (data) => {
  return {
    type: FETCH_STRATEGY_SUCCESS,
    payload: data
  };
};
export const fetchStrategiesFail = (error) => {
  return {
    type: FETCH_STRATEGY_FAIL,
    payload: { error }
  };
};
