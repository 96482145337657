import {
  FETCH_STRATEGY_REQUESTED,
  FETCH_STRATEGY_SUCCESS,
  FETCH_STRATEGY_FAIL,
} from "./actionTypes";
const initalState = {
  strategies: [],
  loading: false,
  error: "",
  modalClear: false,
  strategyResponseMessage: ""
};
const StrategyReducer = (state = { initalState }, action) => {
  switch (action.type) {
    // fetch strategies
    case FETCH_STRATEGY_REQUESTED:
      state = {
        ...state,
        loading: true
      };
      break;
    case FETCH_STRATEGY_SUCCESS:
      state = {
        ...state,
        strategies: [...action.payload],
        totalDocs: action?.payload?.length || 0,
        loading: false,
        error: false
      };
      break;
    case FETCH_STRATEGY_FAIL:
      state = {
        ...state,
        error: true,
        success: false,
        errorDetails: action.payload.error,
        loading: false
      };
      break;

    default:
      state = { ...state };

  }
  return state;
};
export default StrategyReducer;