import {
  FETCH_CLIENT_DEPOSITS_REQUESTED,
  FETCH_CLIENT_DEPOSITS_SUCCESS,
  FETCH_CLIENT_DEPOSITS_FAIL,

  FETCH_CLIENT_SUBSCRIPTION_REQUESTED,
  FETCH_CLIENT_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUBSCRIPTION_FAIL,

  SUBSCRIBE_TO_STRATEGY,
  SUBSCRIBE_TO_STRATEGY_SUCCESS,
  SUBSCRIBE_TO_STRATEGY_FAIL,

  FETCH_CLIENT_PROFIT,
  FETCH_CLIENT_PROFIT_SUCCESS,
  FETCH_CLIENT_PROFIT_FAIL,
} from "./actionTypes";
const initalState = {
  deposits: [],
  profits: [],
  clientSubscription: [],
  loading: false,
  error: "",
  modalClear: false,
  depositResponseMessage: ""
};
const depositReducer = (state = { initalState }, action) => {
  switch (action.type) {
    // fetch client deposits 
    case FETCH_CLIENT_DEPOSITS_REQUESTED:
    case FETCH_CLIENT_SUBSCRIPTION_REQUESTED:
    case SUBSCRIBE_TO_STRATEGY:
    case FETCH_CLIENT_PROFIT:
      state = {
        ...state,
        loading: true
      };
      break;
    case FETCH_CLIENT_DEPOSITS_FAIL:
    case FETCH_CLIENT_SUBSCRIPTION_FAIL:
    case SUBSCRIBE_TO_STRATEGY_FAIL:
    case FETCH_CLIENT_PROFIT_FAIL:
      state = {
        ...state,
        error: true,
        success: false,
        errorDetails: action.payload.error,
        loading: false
      };
      break;
    case FETCH_CLIENT_DEPOSITS_SUCCESS:
      state = {
        ...state,
        deposits: action.payload.result.docs,
        totalDocs: action.payload.result.totalDocs,
        loading: false,
        error: false
      };
      break;
    case FETCH_CLIENT_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        clientSubscription: action.payload.result,
        loading: false,
        error: false
      };
      break;
    case SUBSCRIBE_TO_STRATEGY_SUCCESS:
      state = {
        ...state,
        clientSubscription: [...state.clientSubscription, action.payload],
        loading: false,
        error: false
      };
      break;
    case FETCH_CLIENT_PROFIT_SUCCESS:
      state = {
        ...state,
        profits: action.payload.result.docs,
        totalDocs: action.payload.result.totalDocs,
        loading: false,
        error: false
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default depositReducer;