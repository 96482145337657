export const FETCH_CLIENT_DEPOSITS_REQUESTED = "FETCH_CLIENT_DEPOSITS_REQUESTED";
export const FETCH_CLIENT_DEPOSITS_SUCCESS = "FETCH_CLIENT_DEPOSITS_SUCCESS";
export const FETCH_CLIENT_DEPOSITS_FAIL = "FETCH_CLIENT_DEPOSITS_FAIL";

export const FETCH_CLIENT_SUBSCRIPTION_REQUESTED = "FETCH_CLIENT_SUBSCRIPTION_REQUESTED";
export const FETCH_CLIENT_SUBSCRIPTION_SUCCESS = "FETCH_CLIENT_SUBSCRIPTION_SUCCESS";
export const FETCH_CLIENT_SUBSCRIPTION_FAIL = "FETCH_CLIENT_SUBSCRIPTION_FAIL";

export const SUBSCRIBE_TO_STRATEGY = "SUBSCRIBE_TO_STRATEGY";
export const SUBSCRIBE_TO_STRATEGY_SUCCESS = "SUBSCRIBE_TO_STRATEGY_SUCCESS";
export const SUBSCRIBE_TO_STRATEGY_FAIL = "SUBSCRIBE_TO_STRATEGY_FAIL";

export const FETCH_CLIENT_PROFIT = "FETCH_CLIENT_PROFIT";
export const FETCH_CLIENT_PROFIT_SUCCESS = "FETCH_CLIENT_PROFIT_SUCCESS";
export const FETCH_CLIENT_PROFIT_FAIL = "FETCH_CLIENT_PROFIT_FAIL";
