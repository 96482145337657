import { combineReducers } from "redux";

import orders from "./orders/reducer";
import deposits from "./deposits/reducer";
import products from "./products/reducer";
import withdrawals from "./withdrawals/reducer";
import address from "./address/reducer";
import deliveryFees from "./deliveryFees/reducer";

const goldReducer = combineReducers({
  orders,
  deposits,
  products,
  withdrawals,
  address,
  deliveryFees,
});

export default goldReducer;