import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { fetchWallets } from "store/actions";
import WalletTransactions from "./WalletTransactions";
import OrderHistory from "./OrderHistory";

function History(props) {
  const dispatch = useDispatch();

  const { subPortal } = useSelector(state=>state.Layout);
  const { wallets } = useSelector(state=>state.walletReducer);
  const isDemo = subPortal === "DEMO";
  useEffect(() => {
    if (!wallets || wallets.length === 0)
      dispatch(fetchWallets({
        isDemo,
      }));
  }, []);  

  return (
    <>
      <div className="page-content" style={{
        position: "relative",
      }}>
        <MetaTags>
          <title>{props.t("History")}</title>
        </MetaTags>
        {!isDemo && <WalletTransactions /> }
        <OrderHistory  isDemo={isDemo} />
      </div>
    </>
  );
}

export default withTranslation()(History); 