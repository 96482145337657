import {
  FETCH_GOLD_START,
  FETCH_GOLD_SUCCESS,
  FETCH_GOLD_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  gold: [],
};
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOLD_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_GOLD_SUCCESS:
      state = {
        ...state,
        gold: [...action.payload.docs],
        loading: false,
      };
      break;
    case FETCH_GOLD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default orderReducer;
