import {
  Col, Container, Row
} from "reactstrap";
import MetaTags from "react-meta-tags";

import CardWrapper from "../../components/Common/CardWrapper";
//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function LegalDocuments(props) {
  const files = [
    {
      filename: "TC Arabic - tracked",
      path: "https://my.guldzon.com/assets/TC%20arabic%20-%20tracked.pdf"
    },
    {
      filename: "Cookies arabic - Tracked",
      path: "https://my.guldzon.com/assets/cookies%20arabic%20-%20Tracked.pdf"
    },
    {
      filename: "سياسة اتفاقية العميل",
      path: "https://my.guldzon.com/assets/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9%20%D8%A7%D8%AA%D9%81%D8%A7%D9%82%D9%8A%D8%A9%20%D8%A7%D9%84%D8%B9%D9%85%D9%8A%D9%84.pdf"
    },
    {
      filename: "Privacy-Policy",
      path: "https://my.guldzon.com/assets/Privacy-Policy.pdf"
    },
    {
      filename: "complaints Guld Zon Arabic - Tracked",
      path: "https://my.guldzon.com/assets/complaints%20Guld%20Zon%20Arabic%20-%20Tracked.pdf"
    },
  ];
  return (<>
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Legal Documents")}</title>
      </MetaTags>
      <Container>
        <div className="profile mt-5">
          <>
            <h1 className="mb-4 color-primary">
              {props.t("Legal Documents")}
            </h1>
            <CardWrapper className='mb-5 my-document glass-card shadow'>
              <Row>
                {files.map((file, index) => (
                  <Col sm={12} key={index} className="my-2">
                    <Link to={"#"} onClick={()=>{
                      window.open(file.path, "_blank");
                    }} className="text-decoration-none color-primary d-flex justify-content-center align-items-center"
                    >
                      {file.filename}
                    </Link>
                  </Col>
                ))}
              </Row>
            </CardWrapper>
          </>
        </div>
      </Container>
    </div>
  </>);
}

export default (withTranslation()(LegalDocuments)); 