import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssets } from "store/actions";

const LanguageDropdown = () => {
  const [menu, setMenu] = useState(false);
  const [currency, setCurrency] = useState();
  const [currentImage, setCurrentImage] = useState(null);
  const dispatch = useDispatch();
  const {
    assets
  } = useSelector(state => state.assets);

  useEffect(() => {
    dispatch(fetchAssets());
  }, []);

  useEffect(() => {
    const baseCurrency = localStorage.getItem("GOLD_BASE_CURRENCY");
    if (baseCurrency) {
      setCurrency(JSON.parse(baseCurrency));
    } else {
      if (assets){
        const defaultCurrency = assets.filter(asset => !asset?.isInventory)[0];
        setCurrency(defaultCurrency);
        localStorage.setItem("GOLD_BASE_CURRENCY", JSON.stringify(defaultCurrency));
      }
    }
  }, [assets]);

  useEffect(() => {
    if (currency) {
      const image = require(`../../../assets/currencies/${currency?.symbol?.toUpperCase()}.svg`);
      setCurrentImage(image);
    }
  }, [currency]);

  const changeCurrency = newCurrency => {
    localStorage.setItem("GOLD_BASE_CURRENCY", JSON.stringify(newCurrency));
    const shouldReload = currency !== newCurrency;
    setCurrency(newCurrency);
    if (shouldReload) {
      window.location.reload();
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button" style={{
          width: "50px",
        }}>
          {currentImage && <img src={currentImage.default} alt={currency.symbol} height="18" />}
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {assets.filter(asset => !asset?.isInventory ).map((c, index) => (
            <DropdownItem
              key={index}
              onClick={() => changeCurrency(c)}
              className={`notify-item ${c?.symbol === currency?.symbol ? "active" : "none"
              }`}
            >
              <span>{c.symbol}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
