import * as axiosHelper from "../api_helper";
import qs from "qs";

export const getWallets = async () => {
  const data = await axiosHelper.get("/wallets");
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data?.result;
};

export const addDeposit = async (values) => {
  // const { id, values } = payload;
  const data = await axiosHelper.post("mmtransactions/deposit/", values);
  // if (data.isError) {
  //   throw new Error(data.message);
  // }
  return data;
};

export async function getDeposits(params){
  const result = await axiosHelper.get(`transactions/deposit?${qs.stringify(params)}`);
  if (result.isSuccess === false){
    throw Error("Error while fetching deposits");
  }
  return result;
}

export async function getClientSubscription(params){
  const result = await axiosHelper.get(`mmtransactions/deposit?${qs.stringify(params)}`);
  if (result.isSuccess === false){
    throw Error("Error while fetching deposits");
  }
  return result;
}

export async function getProfit(params){
  const result = await axiosHelper.get(`mmtransactions/deposit?${qs.stringify(params)}`);
  if (result.isSuccess === false){
    throw Error("Error while fetching deposits");
  }
  return result;
}
