export const BUY_GOLD_START = "BUY_GOLD_START";
export const BUY_GOLD_SUCCESS = "BUY_GOLD_SUCCESS";
export const BUY_GOLD_ERROR = "BUY_GOLD_ERROR";
export const BUY_GOLD_CLEAR = "BUY_GOLD_CLEAR";

export const FETCH_GOLD_ORDERS_START = "FETCH_GOLD_ORDERS_START";
export const FETCH_GOLD_ORDERS_SUCCESS = "FETCH_GOLD_ORDERS_SUCCESS";
export const FETCH_GOLD_ORDERS_ERROR = "FETCH_GOLD_ORDERS_ERROR";

export const REQUEST_STORAGE_TO_DELIVER_START = "REQUEST_STORAGE_TO_DELIVER_START";
export const REQUEST_STORAGE_TO_DELIVER_SUCCESS = "REQUEST_STORAGE_TO_DELIVER_SUCCESS";
export const REQUEST_STORAGE_TO_DELIVER_ERROR = "REQUEST_STORAGE_TO_DELIVER_ERROR";

export const RESELL_GOLD_START = "RESELL_GOLD_START";
export const RESELL_GOLD_SUCCESS = "RESELL_GOLD_SUCCESS";
export const RESELL_GOLD_ERROR = "RESELL_GOLD_ERROR";

export const CLEAR_GOLD_INVENTORY_TRANSACTIONS = "CLEAR_GOLD_INVENTORY_TRANSACTIONS";