import React from "react";
import { Redirect } from "react-router-dom";
//Dashboard
import Dashboard from "../pages/MoneyManagement/Dashboard/index";
import Wallet from "../pages/Crypto/Wallet";
import Documents from "../pages/Documents";
import History from "../pages/MoneyManagement/History";
import BankAccounts from "pages/Crypto/BankAccounts/BankAccounts";
import Activities from "pages/Crypto/Activities";
import Profile from "pages/Authentication/Profile";
import nonAuthRoutes from "./nonAuthRoutes";
import TwoFA from "../pages/Authentication/2FA";

export default [ 
  {
    path: "/dashboard",
    component: Dashboard,
    isAuth: true
  },
  //profile
  {
    path: "/documents",
    component: Documents,
    isAuth: true 
  },
  {
    path: "/wallet",
    component: Wallet,
    isAuth: true 
  },
  {
    path: "/history",
    component: History,
    isAuth: true 
  },
  {
    path: "/two-fa",
    component: TwoFA,
    isAuth: true 
  },
  {
    path: "/bank-account",
    component: BankAccounts,
    isAuth: true 
  },
  {
    path: "/activities",
    component: Activities,
    isAuth: true 
  },
  {
    path: "/profile",
    component: Profile,
    isAuth: true 
  },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    isAuth: true,
    component: () => <Redirect to="/dashboard" /> 
  },
  ...nonAuthRoutes
];