import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import { FETCH_GOLD_WITHDRAWALS_START } from "./actionTypes";
import { getGoldWithdrawals } from "apis/gold";
import { fetchGoldWithdrawalsError, fetchGoldWithdrawalsSuccess } from "./actions";

function* fetchGoldDeposits({ payload }) {
  try {
    const data = yield call(getGoldWithdrawals, payload);
    yield put(fetchGoldWithdrawalsSuccess(data));
  } catch (error) {
    yield put(fetchGoldWithdrawalsError(error));
  }
}

function* depositsSaga() {
  yield takeEvery(FETCH_GOLD_WITHDRAWALS_START, fetchGoldDeposits);
}

export default depositsSaga;