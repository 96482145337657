import {
  FETCH_GOLD_WITHDRAWALS_ERROR,
  FETCH_GOLD_WITHDRAWALS_START,
  FETCH_GOLD_WITHDRAWALS_SUCCESS
} from "./actionTypes";

export const fetchGoldWithdrawals = (params = {} ) => {
  return {
    type: FETCH_GOLD_WITHDRAWALS_START,
    payload: params,
  };
};

export const fetchGoldWithdrawalsSuccess = (data) => {
  return {
    type: FETCH_GOLD_WITHDRAWALS_SUCCESS,
    payload: data,
  };
};

export const fetchGoldWithdrawalsError = (error) => {
  return {
    type: FETCH_GOLD_WITHDRAWALS_ERROR,
    payload: { error },
  };
};