import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoldDeposits } from "store/gold/deposits/actions";
import SharedTransactions from "../Shared/SharedTransactions";
import { useTranslation } from "react-i18next";
import { getAssetImgSrc } from "helpers/assetImgSrc";

export default function Deposits() {
  const { t } = useTranslation();
  const { deposits = [], loading } = useSelector((state) => state.goldReducer.deposits);
  const { subPortal } = useSelector((state) => state.Layout);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGoldDeposits({
      isDemo: subPortal === "DEMO",
    }));
  }, []);
  const columns = [
    {
      dataField: "",
      text: "",
    },
    {
      text: t("Asset"),
      dataField: "walletId",
      formatter: (item) => (
        <div className="balances__company">
          <div className="balances__logo">
            <img src={getAssetImgSrc(item.walletId?.assetId)} alt="" />
          </div>
          <div className="balances__text">
            {item?.walletId?.asset ? item.walletId.asset : ""}
          </div>
        </div>
      ),
    },
    {
      text: t("Type"),
      dataField: "type",
    },
    {
      text: t("Date"),
      dataField: "createdAt",
      formatter: (item) => {
        let d = new Date(item.createdAt);
        d = `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
        return d;
      },
    },
    {
      text: t("Amount"),
      dataField: "amount",
      formatter: (item) =>
        item.amount && item.amount["$numberDecimal"]
          ? parseFloat(item.amount["$numberDecimal"])?.toFixed(2)
          : parseFloat(item.amount)?.toFixed(2),
    },
    {
      text: t("Status"),
      dataField: "status",
    },
  ];
  return (
    <SharedTransactions data={deposits} loading={loading} t={t} columns={columns} />
  );
}
