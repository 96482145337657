import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import {
  FETCH_CLIENT_DEPOSITS_REQUESTED,
  FETCH_CLIENT_SUBSCRIPTION_REQUESTED,
  SUBSCRIBE_TO_STRATEGY,
  FETCH_CLIENT_PROFIT,
} from "./actionTypes";
import {
  fetchClientDepositsSuccess, fetchClientDepositsFail,
  fetchClientSubscriptionSuccess, fetchClientSubscriptionFail,
  SubToStrategySuccess, SubToStrategyFail,
  fetchClientProfitSuccess, fetchClientProfitFail,
} from "./actions";
import {
  getDeposits, addDeposit, getClientSubscription, getProfit
} from "apis/mm/deposit";
import { showErrorNotification, showSuccessNotification } from "store/general/notifications/actions";
import { toggleCurrentModal } from "store/actions";


function * fetchClientDeposits(params){
  try {
    const data = yield call(getDeposits, params.payload);
    yield put(fetchClientDepositsSuccess(data));
  } catch (error){ 
    yield put(fetchClientDepositsFail({ error: error.message }));
  }
}
function * fetchClientSubscription(params){
  try {
    const data = yield call(getClientSubscription, params.payload);
    yield put(fetchClientSubscriptionSuccess(data));
  } catch (error){ 
    yield put(fetchClientSubscriptionFail({ error: error.message }));
  }
}
function* subscribeToStrategy({ payload }) {
  try {
    const result = yield call(addDeposit, payload);
    if (result.status) {
      yield put(SubToStrategySuccess(result.result));
      yield put(showSuccessNotification("Subscribed successfully!"));
      yield put(toggleCurrentModal(""));
    }
  } catch (error) {
    yield put(SubToStrategyFail(error.message));
    yield put(showErrorNotification("Failed while subscribing!"));
  }
}
function * fetchClientProfit(params){
  try {
    const data = yield call(getProfit, params.payload);
    yield put(fetchClientProfitSuccess(data));
  } catch (error){ 
    yield put(fetchClientProfitFail({ error: error.message }));
  }
}

function * depositSaga(){
  yield takeEvery(FETCH_CLIENT_DEPOSITS_REQUESTED, fetchClientDeposits);
  yield takeEvery(FETCH_CLIENT_SUBSCRIPTION_REQUESTED, fetchClientSubscription);
  yield takeEvery(SUBSCRIBE_TO_STRATEGY, subscribeToStrategy);
  yield takeEvery(FETCH_CLIENT_PROFIT, fetchClientProfit);
}
  
export default depositSaga;