import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import { getWithdrawals, addWithdraw } from "apis/mm/withdraw";
import {
  FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED,
  UNSUBSCRIBE_STRATEGY,
} from "./actionTypes";
import {
  fetchClientWithdrawalsSuccess,
  fetchClientWithdrawalsFail, unsubStrategySuccess, unsubStrategyFail
} from "./actions";
import { showErrorNotification, showSuccessNotification } from "store/general/notifications/actions";
import { toggleCurrentModal } from "store/actions";
  
function * fetchClientWithdrawals(params){
  try {
    const data = yield call(getWithdrawals, params.payload);
    yield put(fetchClientWithdrawalsSuccess(data));
  } catch (error){ 
    yield put(fetchClientWithdrawalsFail({ error: error.message }));
  }
}

function* unsubscribeStrategy({ payload }) {
  try {
    const result = yield call(addWithdraw, payload);
    if (result.status) {
      yield put(unsubStrategySuccess(result.result));
      yield put(showSuccessNotification("Unsubscription request has been sent."));
      yield put(toggleCurrentModal(""));
    } else {
      yield put(unsubStrategyFail(result.message));
      yield put(showErrorNotification(result.message));
    }
  } catch (error) {
    yield put(unsubStrategyFail(error.message));
    yield put(showErrorNotification("Failed while unsubscribing!"));
  }
}
  
function * withdrawalSaga(){
  yield takeEvery(FETCH_CLIENT_MM_WITHDRAWALS_REQUESTED, fetchClientWithdrawals);
  yield takeEvery(UNSUBSCRIBE_STRATEGY, unsubscribeStrategy);
}

export default withdrawalSaga;